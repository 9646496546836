app.controller('SideBarCtrl', function($scope, $route, $timeout, $location, $window, $translate, Notification,
  AuthService, LoaderFactory, SidebarFactory, ReducedService, $rootScope, APP_TUTORIAL, LocalizationService, AppFactory, API) {

  $scope.currentUser = AuthService.getAuthorizedUser();

  $scope.isActiveLanguageMenu   = false;
  $scope.isActiveSidebar        = false;
  $scope.visibleContainerOfUser = !ReducedService.login;

  $scope.APP_TUTORIAL = APP_TUTORIAL;

  $scope.langList        = LocalizationService.supportLangs;
  $rootScope.currentLang = localStorage.getItem('lang');

  $scope.isDevice        = !!window.cordova;
  $scope.deviceIsIOS     = deviceIsIOS;
  $scope.deviceIsAndroid = deviceIsAndroid;

  $scope.logout = function(e) {
    e.stopPropagation();

    return Promise.resolve()
    .then(() => Notification.confirm_p({
      title: 'notice',
      desc: 'confirmLogoutMessage',
      button: 'logout',
      cancelButton: 'cancel'
    }))
    .then((res) => {
      if (res === 2) {
        LoaderFactory.show();
        return AuthService.logout();
      }
    })
    .catch((err) => {
      return err.notify();
    })
    .then(() => LoaderFactory.hide());
  };

  $scope.handlerClick = function(to) {
    $rootScope.readOnly = false;
    SidebarFactory.hide();
    return $location.path(to);
  };

  $scope.openLink = function(link) {
    return $window.open(link, '_system');
  };

  $scope.openLinkByTranslation = function(link) {
    $translate([link]).then(function(t) {
      return $window.open(t[link], '_system');
    });
  };

  $scope.openAnotherApp = function(params) {
    if (window.cordova) {
      var sApp;

      if (deviceIsIOS) {
        sApp = startApp.set(params.ios.appId);
      } else if (deviceIsAndroid) {
        sApp = startApp.set(params.android.appId);
      }

      sApp.start(function(res) {
        //console.log(res);
      }, function(error) {
        if (deviceIsIOS && params.ios.storeUrl) {
          return $scope.openLink(params.ios.storeUrl);
        } else if (deviceIsAndroid && params.android.storeUrl) {
          return $scope.openLink(params.android.storeUrl);
        }
      });
    }

    return false;
  };

  $scope.getFlag = (code) => {
    switch(code) {
      case 'de':
        return 'images/flags/germany.svg';
      case 'en':
        return 'images/flags/united-kingdom.svg';
      case 'ro':
        return 'images/flags/romania.svg';
      case 'po':
        return 'images/flags/poland.svg';
      case 'tr':
        return 'images/flags/turkey.svg';
      case 'hu':
        return 'images/flags/hungary.svg';
      case 'ru':
        return 'images/flags/russia.svg';
      case 'cs':
        return 'images/flags/czech-republic.svg';
      case 'hr':
        return 'images/flags/croatia.svg';
      case 'uk':
        return 'images/flags/ukraine.svg';
      case 'es':
        return 'images/flags/spain.svg';
      case 'it':
        return 'images/flags/italy.svg';
      case 'fr':
        return 'images/flags/france.svg';
    };
  }

  $scope.getLangName = (code) => {
    switch(code) {
      case 'de':
        return 'Deutsch';
      case 'en':
        return 'English';
      case 'ro':
        return 'Română';
      case 'po':
        return 'Polski';
      case 'tr':
        return 'Türk';
      case 'hu':
        return 'Magyar';
      case 'ru':
        return 'Русский';
      case 'cs':
        return 'Čeština';
      case 'hr':
        return 'Hrvatski';
      case 'uk':
        return 'Українська';
      case 'es':
        return 'Español';
      case 'it':
        return 'Italiano';
      case 'fr':
        return 'Français';
    };
  }

  $scope.onSwitchLang = function (e, langCode) {
    $rootScope.currentLang = langCode;
    localStorage.setItem('selectedLang', langCode);
    LocalizationService.localization(langCode);
    $scope.toggleLanguageMenu(e);
    return $translate.use(langCode)
    .then(() => $translate($route.current.$$route.title))
    .then(title => AppFactory.setTitle(title))
    .then(() => API.saveLocaleToBackend(langCode))
    .then(() => Notification.alert_p({
      title: 'note',
      desc: 'languageChanged',
      button: 'ok'
    }))
    .catch((err) => {
      return err.notify();
    })
  }

  $scope.toggleLanguageMenu = function(e) {
    e.stopPropagation();
    return $scope.isActiveLanguageMenu = !$scope.isActiveLanguageMenu;
  };

  SidebarFactory.on('changed:state', function() {
    return $timeout(function() {
      $scope.isActiveSidebar = SidebarFactory.isActive();
      return $scope.isActiveLanguageMenu = false;
    });
  });

  AuthService.on('user.updated', function() {
    return $timeout(function() {
      $scope.currentUser = AuthService.getAuthorizedUser();
    });
  });
});
